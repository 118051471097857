<script>
import mpx, { createApp } from '@mpxjs/core'
import apiProxy from '@mpxjs/api-proxy'

mpx.use(apiProxy, { usePromise: true })

createApp({
  onLaunch() {
    mpx.showShareMenu({
      withShareTicket: true,
      menus: ['shareAppMessage', 'shareTimeline']
    })
  }
})
</script>

<style>
  /*此处编写全局样式*/
</style>

<script type="application/json">
  {
    "pages": [
      "./pages/index"
    ]
  }
</script>
