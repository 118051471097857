/* template */
<template>
<div id="app"><transition :name="transitionName"><mpx-keep-alive><router-view></router-view></mpx-keep-alive></transition></div></template>
/* styles */
<style mpxStyleOptions='{"mid":"mpx-app-scope"}'>
  /*此处编写全局样式*/
</style>

/* json */
/* script */
<script>
  import { processComponentOption, getComponent, getWxsMixin } from "@mpxjs/webpack-plugin/lib/runtime/optionProcessor"
  var wxsModules = {}
  global.currentModuleId = "mpx-app-scope"
  global.currentSrcMode = "wx"
  global.currentInject = {"moduleId":"mpx-app-scope"}
  /** script content **/
  require("./app.mpx.js?isApp!=!@mpxjs/webpack-plugin/lib/selector?mode=web&env=!./app.mpx?ctorType=app&index=0&isApp&lang=js&mpx&type=script")

  export default processComponentOption({
    option: global.__mpxOptionsMap["mpx-app-scope"],
    ctorType: "app",
    outputPath: "",
    pageConfig: {},
    // @ts-ignore
    componentsMap: {'mpx-keep-alive':getComponent(require("@mpxjs/webpack-plugin/lib/runtime/components/web/mpx-keep-alive.vue?isComponent"), {__mpxBuiltIn: true})},
    componentGenerics: {},
    genericsInfo: undefined,
    wxsMixin: getWxsMixin(wxsModules),
    hasApp: true
  })
</script>