<template>
  <list></list>
</template>

<script>
import { createPage } from '@mpxjs/core'

createPage({
  onLoad() {
    //
  }
})
</script>

<script type="application/json">
  {
    "usingComponents": {
      "list": "../components/list"
    },
    "navigationBarTitleText": "现代艺术 150 年概览"
  }
</script>
