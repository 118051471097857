import { render, staticRenderFns } from "./list.mpx?vue&type=template&id=57afcb1c&isComponent&outputPath=components%2Flistef6e5fec%2Findex"
import script from "./list.mpx?vue&type=script&lang=js&isComponent&outputPath=components%2Flistef6e5fec%2Findex"
export * from "./list.mpx?vue&type=script&lang=js&isComponent&outputPath=components%2Flistef6e5fec%2Findex"
import style0 from "./list.mpx?vue&type=style&index=0&id=57afcb1c&prod&lang=css&mpxStyleOptions=%7B%22mid%22%3A%22_ef6e5fec%22%7D&isComponent&outputPath=components%2Flistef6e5fec%2Findex"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@7.1.2_webpack@5.95.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports