/* template */
<template><page><list></list></page></template>
/* styles */
/* json */
/* script */
<script>
  import { processComponentOption, getComponent, getWxsMixin } from "@mpxjs/webpack-plugin/lib/runtime/optionProcessor"
  var wxsModules = {}
  global.currentModuleId = "_31f86cda"
  global.currentSrcMode = "wx"
  global.currentInject = {"moduleId":"_31f86cda"}
  /** script content **/
  require("./index.mpx.js?isFirst&isPage!=!@mpxjs/webpack-plugin/lib/selector?mode=web&env=!./index.mpx?ctorType=page&index=0&isFirst&isPage&lang=js&mpx&type=script")

  export default processComponentOption({
    option: global.__mpxOptionsMap["_31f86cda"],
    ctorType: "page",
    outputPath: "",
    pageConfig: {"navigationBarTitleText":"现代艺术 150 年概览"},
    // @ts-ignore
    componentsMap: {'list':getComponent(require("../components/list.mpx?isComponent&outputPath=components%2Flistef6e5fec%2Findex"))},
    componentGenerics: {},
    genericsInfo: undefined,
    wxsMixin: getWxsMixin(wxsModules),
    hasApp: true
  })
</script>