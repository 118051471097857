import '@mpxjs/webpack-plugin/lib/runtime/base.styl'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Mpx from '@mpxjs/core'
import { processAppOption, getComponent } from "@mpxjs/webpack-plugin/lib/runtime/optionProcessor"
Vue.use(VueRouter)

  global.getApp = function () {}
  global.getCurrentPages = function () {
    if (!(typeof window !== 'undefined')) {
      console.error('[Mpx runtime error]: Dangerous API! global.getCurrentPages is running in non browser environment, It may cause some problems, please use this method with caution')
    }
    var router = global.__mpxRouter
    if (!router) return []
    // @ts-ignore
    return (router.lastStack || router.stack).map(function (item) {
      var page
      var vnode = item.vnode
      if (vnode && vnode.componentInstance) {
        page = vnode.tag.endsWith('mpx-tab-bar-container') ? vnode.componentInstance.$refs.tabBarPage : vnode.componentInstance
      }
      return page || { route: item.path.slice(1) }
    })
  }
  global.__networkTimeout = undefined
  global.__mpxGenericsMap = {}
  global.__mpxOptionsMap = {}
  global.__style = "v1"
  global.__mpxPageConfig = undefined
  global.__mpxTransRpxFn = undefined
var App = require("./app.mpx?isApp").default

export default processAppOption({
  App: App,
  tabBarMap: undefined,
  firstPage: "pages/index",
  pagesMap: {'pages/index':getComponent(require("./pages/index.mpx?isFirst&isPage"), {__mpxPageRoute: "pages/index"})},
  componentsMap: {},
  Vue: Vue,
  VueRouter: VueRouter,
  el: "#app"
})
